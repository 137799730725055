import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ICompetencyModel} from '../../core/interfaces/competency.interface';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'assign-competencies',
  templateUrl: './assign-competencies.component.html'
})
export class AssignCompetenciesComponent implements OnInit {
  @Input() public maxAssignedCompetenciesAllowed = 10;
  @Input() public assignedCompetencies:  ICompetencyModel[];
  @Input() public availableCompetencies: ICompetencyModel[];
  @Output() public competencyAssigned = new EventEmitter<ICompetencyModel>();
  @Output() public competencyUnassigned = new EventEmitter<ICompetencyModel>();
  @Output() public selectedCompetencyChanged = new EventEmitter<ICompetencyModel[]>();

  public showAvailableAsList = true;
  public availableFilter = '';
  public assignedFilter = '';
  public assignedIds: number[];
  private flatCompetencies: ICompetencyModel[];

  public ngOnInit() {
    this.assignedIds = this.assignedCompetencies.map(c => c.competencyId);
    this.flatCompetencies = this.getCompetencies(this.availableCompetencies);
  }

  public onListTypeChange() {
    if (!this.showAvailableAsList) {
      this.availableFilter = '';
    }
  }

  public assignFilterChange(filterValue: string) {
    this.assignedFilter = filterValue;
  }

  public competencyFilterChange(filterValue: string) {
    this.availableFilter = filterValue;
    if (this.availableFilter) {
      this.showAvailableAsList = true;
    }
  }

  public get filteredAvailableCompetencies(): ICompetencyModel[] {
    if (!this.availableFilter) {
      return this.flatCompetencies;
    }
    const regEx = new RegExp(this.availableFilter, 'gi');
    const filtered = this.flatCompetencies.filter((c) => regEx.test(c.name));
    return filtered;
  }

  public get filteredSelectedCompetencies(): ICompetencyModel[] {
    if (this.assignedFilter) {
      const regEx = new RegExp(this.assignedFilter, 'gi');
      return this.assignedCompetencies.filter((c) => regEx.test(c.name));
    }
    return this.assignedCompetencies;
  }

  public competencyClicked(competency: ICompetencyModel, assign: boolean) {
    if (!competency || competency.competencyType.toLowerCase() === 'g') {
      return;
    }
    if (assign) {
      this.assignCompetency(competency);
      this.competencyAssigned.emit(competency);
    } else {
      this.unassignCompetency(competency);
      this.competencyUnassigned.emit(competency);
    }
    this.selectedCompetencyChanged.emit(this.assignedCompetencies);
    this.flatCompetencies = this.getCompetencies(this.availableCompetencies);
  }

  private getCompetencies(list: ICompetencyModel[]): ICompetencyModel[] {
    if (isNullOrUndefined(list)) {
      return [];
    }

    let model = [];
    for (const comp of list) {
      if (comp.competencyType.toLowerCase() === 'c' && this.assignedIds.indexOf(comp.competencyId) === -1) {
        model.push(comp);
        continue;
      }
      if (comp.children && comp.children.length > 0) {
        model = model.concat(this.getCompetencies(comp.children));
      }
    }
    return model;
  }

  private unassignCompetency(competency: ICompetencyModel) {
    if (competency) {
      // remove the competency that was selected
      this.assignedCompetencies = this.assignedCompetencies.filter((c) => c.competencyId !== competency.competencyId);
      this.assignedIds = this.assignedCompetencies.map(c => c.competencyId);
    }
  }

  private assignCompetency(competency: ICompetencyModel) {
    if (competency) {
      if (this.assignedIds.indexOf(competency.competencyId) === -1) {
        if (this.assignedCompetencies.length < this.maxAssignedCompetenciesAllowed) {
          this.assignedCompetencies = this.assignedCompetencies.concat([competency]);
          this.assignedIds = this.assignedCompetencies.map(c => c.competencyId);
        } else {
          alert(`You can only select up to ${this.maxAssignedCompetenciesAllowed} competencies!`);
          // TODO: admins should be able to add any number of competencies
        }
      }
    }
  }
}
