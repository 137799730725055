<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="confirm(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [innerHtml]='body'></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="confirm(true)">OK</button>
</div>
