import {Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PsListItem} from '../../core/listitem/ps-list-item';
import {AutoCompleteSelectSettings} from '../ps-autocomplete-settings';
import {DOWN_ARROW, END, ENTER, ESCAPE, HOME, TAB, UP_ARROW} from '../../core/keyboard/keycodes';
import {Subject} from 'rxjs';
import {PsOptionsComponent} from '../ps-options/ps-options';

let _uniqueFilterId = 0;

@Component({
  selector: 'ps-autocomplete-select',
  styleUrls: ['../styles/ps-autocomplete.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './ps-autocomplete-select.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PsAutoCompleteSelectComponent),
    multi: true
  }]
})
export class PsAutoCompleteSelectComponent implements OnInit, ControlValueAccessor {
  @Input() settings: AutoCompleteSelectSettings;
  @Input() placeHolderText: string;
  @Input() selectList: PsListItem[];
  @Input() selectedItem: PsListItem;
  @Input() disabled: boolean;
  @Input() debug = false;
  @Output() selectionChanged: EventEmitter<PsListItem> = new EventEmitter<PsListItem>();
  @ViewChild(PsOptionsComponent) optionComp: PsOptionsComponent;
  @ViewChild('filterInput') filterInputElem: ElementRef;

  public listHidden = true;
  public filtered: PsListItem[];
  public data: string;
  public id = `select-filter-${_uniqueFilterId++}`;
  public showPlaceHolder = true;
  private _tabOut: Subject<any> = new Subject();

  defaultSettings: AutoCompleteSelectSettings = {
    itemsToShow: 500,
    showOnFocus: true,
    hideOnBlur: true,
    listHeight: 256,
    location: 'bottom',
    clearFilterOnFocus: true
   } as AutoCompleteSelectSettings;

  constructor(private eRef: ElementRef) { }


  ngOnInit(): void {
    this.initSettings();
    this.filtered = this.selectList;
    this.data = this.getValue();
    this.writeConsole(this);
  }

  private propagateChange = (v: any) => {};
  private propagateTouch = (v: any) => {};

  public registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  public registerOnChange(fn: any): void {
    this.writeConsole('Registering on change function');
    this.propagateChange = fn;
  }

  writeValue(obj: PsListItem): void {
    this.writeConsole('Writing value: ');
    if (obj) {
      this.selectedItem = obj;
      this.data = this.selectedItem.name;
      this.propagateChange(this.selectedItem);
      this.closePanel();
    }
  }

  writeEmptyValue() {
    this.writeConsole('Writing empty value');
    this.selectedItem = null;
    this.propagateChange(null);
  }

  focusInput() {
    this.writeConsole('Focus Input');
    if (this.settings.clearFilterOnFocus) {
      this.filterInputElem.nativeElement.value = '';
    } else {
      this.filterInputElem.nativeElement.value = !this.selectedItem
        ? ''
        : this.selectedItem.name;
    }
    this.filterInputElem.nativeElement.focus();
  }

  reset() {
    this.writeConsole('Reset');
    this.optionComp.reset();
    this.writeEmptyValue();
    this.focusInput();
  }

  getListClass() {
    return this.settings && this.settings.location.toLowerCase() === 'top'
      ? 'select-list-top'
      : 'select-list';
  }

  onSelectionChanged(item: PsListItem) {
    this.writeConsole('Selection Changed');
    this.writeValue(item);
    this.selectionChanged.emit(item);
  }

  closePanel() {
    this.writeConsole('Close Panel');
    this.showPlaceHolder = true;
    this.listHidden = true;
    this.filterInputElem.nativeElement.value = '';
    this.data = '';
  }

  openPanel() {
    if(this.disabled)
      return;
    this.writeConsole('Open Panel');
    this.listHidden = false;
    this.showPlaceHolder = false;
  }

  onFocus(event) {
    if(this.disabled)
      return;
    this.writeConsole('Focus Event');
    if (this.settings.showOnFocus) {
      const term = event.target.value;
      this.filtered = this.filterList(term);
      this.openPanel();
    }
  }

  @HostListener('document:click', ['$event'])
  clickWatch(event: any) {
    this.writeConsole('click event', event);
    let inside = false
    const contains = this.eRef.nativeElement.contains(event.target);
    if (contains) {
      inside = true;
    } else if (event.target.className === 'placeholder-text' || event.target.className === 'active-text') {
      inside = true;
    }

    if (!inside && this.settings.hideOnBlur) {
      this.closePanel();
      return;
    }
  }

  onKeyDown(event: KeyboardEvent) {
    this.writeConsole('Keydown Event', event.keyCode);
    if (event.keyCode === ENTER) {
      this.selectActiveItem();
    } else {
      this.handleKeyDown(event);
      if (event.keyCode === UP_ARROW || event.keyCode === DOWN_ARROW) {
        this.openPanel();
      }
    }
  }

  onKeyUp(event) {
    this.writeConsole('Keyup Event', event.keyCode);
    if (event.keyCode === ESCAPE) {
      this.closePanel();
    }
    if (event.keyCode === UP_ARROW || event.keyCode === DOWN_ARROW) {
      return;
    }
    const term = event.target.value;
    this.data = term;
    this.filtered = this.filterList(term);
  }

  getValue(): string {
    this.writeConsole('Get Value Event');
    return !this.selectedItem ? '' : this.selectedItem.name;
  }

  private handleKeyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case DOWN_ARROW:
        this.optionComp.setNextItemActive();
        break;
      case UP_ARROW:
        this.optionComp.setPrevItemActive();
        break;
      case HOME:
        this.optionComp.setFirstItemActive();
        break;
      case END:
        this.optionComp.setLastItemActive();
        break;
      case TAB:
        // Note that we shouldn't prevent the default action on tab.
        this.tabOut().next(null);
        return;
      default:
        return;
    }

    event.preventDefault();
  }

  private selectActiveItem() {
    this.optionComp.selectActiveItem();
  }

  private tabOut(): Subject<void> {
    return this._tabOut;
  }

  private filterList(term: string = null): PsListItem[] {
    return (term == null || term.length === 0)
      ? this.selectList.slice(0, this.settings.itemsToShow)
      : this.selectList.filter((c) => new RegExp(term, 'gi').test(c.name)).splice(0, this.settings.itemsToShow);
  }

  private initSettings() {
    if (!this.settings) {
      this.settings = this.defaultSettings;
    }
  }

  private writeConsole(val: any, val2: any = null) {
    if (this.debug) {
      if (val2) {
        console.log(val, val2);
      } else {
        console.log(val);
      }
    }
  }


}
