<div class="modal-header">
  <h4 class="modal-title">Set Assessment Request Status</h4>
  <button type="button" class="close" aria-label="Close" (click)="onDismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <label class="form-control-label" for="status-select">Status</label>
  <select [ngbTooltip]="(!assessmentRequest.consultantAssigned) ? 'Option Disabled: &quot;Complete&quot;. Consultant must be assigned.' : ''" container="body" id="status-select" class="form-control" [(ngModel)]="assessmentRequest.statusId" >
    <option value="1">Not Submitted</option>
    <option value="5">Submitted</option>
    <option value="6">In Process</option>
    <option value="11">In Process: Reports Uploaded</option>
    <option value="12">In Process: Testing Started</option>
    <option value="9">In Process: Testing Complete</option>
    <option value="10">Canceled</option>
    <option [disabled]="!assessmentRequest.consultantAssigned" value="7">Complete</option>
  </select>
</div>
<div class="modal-footer">
  <div class="col">
        <span class="pull-right">
            <button type="button" class="btn btn-primary" (click)="onClose()"><span class="fa fa-plus"></span> Save</button>
            <button type="button" class="btn btn-cancel" (click)="onDismiss()"><span class="fa fa-ban"></span> Cancel</button>
      </span>
  </div>
</div>
