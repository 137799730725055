<div class="modal-header">
  <h4 class="modal-title">Select Department</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="col">
    <ps-hierarchy [selectedNode]="selectedNode" [nodes]="nodes" [multiSelect]="false"
                  (nodeSelectionChanged)="setSelected($event)" *ngIf="nodes && nodes.length"
                  id="departments">
    </ps-hierarchy>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="onSelect()"><span class="fa fa-check"></span> Select</button>
  <button type="button" class="btn btn-default" (click)="onCancel()"><span class="fa fa-close"></span> Close</button>
</div>
