<div class="modal-header">
  <h4 class="modal-title">{{verb}} Invoice Recipient</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ps-sections-container [formGroup]="form" *ngIf="form" [showNav]="false">
    <div class="form-group row">
      <ps-input-validation>
        <label class="col-form-label" for="billing-contact-first-name">First Name *</label>
        <input psInput type="text" class="form-control" #bfirstName id="billing-contact-first-name" formControlName="firstName">
      </ps-input-validation>
      <ps-input-validation>
        <label class="form-control-label" for="billing-contact-middle-name">Middle Name</label>
        <input psInput type="text" class="form-control" #bmiddleName id="billing-contact-middle-name" formControlName="middleName">
      </ps-input-validation>
      <ps-input-validation>
        <label class="form-control-label" for="billing-contact-last-name">Last Name *</label>
        <input psInput type="text" class="form-control" #blastName id="billing-contact-last-name" formControlName="lastName">
      </ps-input-validation>
    </div>
    <div class="form-group row">
      <ps-input-validation>
        <label class="form-control-label" for="billing-contact-phone">Phone *</label>
        <input psInput type="tel" class="form-control" #phone id="billing-contact-phone" formControlName="phone">
      </ps-input-validation>
      <ps-input-validation>
        <label class="form-control-label" for="billing-contact-email">Email *</label>
        <input psInput type="email" class="form-control" #email id="billing-contact-email" formControlName="email">
      </ps-input-validation>
    </div>
    <div class="form-group row">
      <ps-input-validation>
        <label class="form-control-label" for="billing-contact-address1">Address 1 / Entity Name *</label>
        <input psInput type="text" class="form-control" #address1 id="billing-contact-address1" formControlName="address1">
      </ps-input-validation>
    </div>
    <div class="form-group row">
      <ps-input-validation>
        <label class="form-control-label" for="billing-contact-address2">Address 2</label>
        <input psInput type="text" class="form-control" #address2 id="billing-contact-address2" formControlName="address2">
      </ps-input-validation>
    </div>
    <div class="form-group row">
      <ps-input-validation>
        <label class="form-control-label" for="billing-contact-city">City *</label>
        <input psInput type="text" class="form-control" #city id="billing-contact-city" formControlName="city">
      </ps-input-validation>
    </div>
    <div class="form-group row">
      <ps-input-validation>
          <label class="form-control-label" for="billing-contact-country">Country *</label>
          <select psInput class="form-control" id="billing-contact-country" formControlName="country" (change)="onSelectCountry($event.target.value)">
            <option value="-1"></option>
            <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>
          </select>
        </ps-input-validation>
      <ps-input-validation *ngIf="isUSA">
        <label class="form-control-label" for="billing-contact-state">State</label>
        <select psInput class="form-control" id="billing-contact-state" formControlName="state">
          <option *ngFor="let state of states" [value]="state.id">{{state.name}}</option>
        </select>
      </ps-input-validation>
      <ps-input-validation *ngIf="!isUSA">
        <label class="form-control-label" for="province">State/Province/Region</label>
        <input psInput type="text" class="form-control" id="billing-contact-province" formControlName="province">
      </ps-input-validation>
      <ps-input-validation>
        <label class="form-control-label" for="billing-contact-zip-code">{{isUSA?'Zip Code':'Postal Code'}}</label>
        <input psInput type="text" class="form-control" #zipCode id="billing-contact-zip-code" formControlName="zipCode">
      </ps-input-validation>
    </div>
  </ps-sections-container>
</div>
<div class="modal-footer row">
  
  <div class="col" *ngIf="!billingContact">
    <button type="button" class="btn btn-secondary pull-left" (click)="onCopyUserDetails()">Use My Details</button>
  </div>
  <div class="col">
    <button *ngIf="billingContact" type="button" class="btn btn-danger pull-left" (click)="onDelete()">Delete</button>
  </div>
  <div class="col">
    <span class="pull-right">
    <button type="button" class="btn btn-cancel" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onClose()" [disabled]="disableAddButton">Save</button>
  </span>
  </div>
</div>
