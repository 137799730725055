<div class="modal-header">
  <h4 class="modal-title">Additional Services Already Requested</h4>
  <button type="button" class="close" aria-label="Close" (click)="onDismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [innerHTML]="description"></div>
</div>
<div class="modal-footer">
  <div class="col">
        <span class="pull-right">
            
            <button *ngIf="!(disableVerbal && disableWritten)" type="button" class="btn btn-secondary" (click)="goBack()"><span class="fa fa-ban"></span> Go Back</button>
            <button type="button" class="btn btn-primary" (click)="onDismiss()"><span class="fa fa-alert"></span>{{(disableVerbal && disableWritten)?' Close':' Okay'}}</button>
      </span>
  </div>
</div>
