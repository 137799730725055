<div class="modal-header">
  <h4 class="modal-title">Recipient of Assessment Results</h4>
  <button type="button" class="close" aria-label="Close" (click)="onDismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="quick-search-container">
    Quick Search:
    <input type="text" [(ngModel)]="_search" id="filter-text-box" placeholder="Type here..."/>
  </div>
  <table class="table table-striped table-sm">
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Phone</th>
      <th>Type</th>
      <th>Verbal Debrief</th>
      <th>Report Notification</th>
      <th>Set Default</th>
    </tr>
    <tr *ngFor="let user of availableUsers | quickSearch: _search">
      <!-- <td class="text-center">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox" [(ngModel)]="user.selected">
          </label>
        </div>
      </td> -->
      <td>{{user.name}}</td>
      <td>{{user.email}}</td>
      <td>{{user.phoneWork}}</td>
      <td>{{user.isSystem ? 'System' : 'Non-System'}}</td>
      <td class="text-center">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox" [(ngModel)]="user.verbal">
          </label>
        </div>
      </td>
      <td class="text-center">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox" [(ngModel)]="user.written">
          </label>
        </div>
      </td>
      <td class="text-center">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox" [(ngModel)]="user.setDefault">
          </label>
        </div>
      </td>
    </tr>
  </table>
  <ng-container *ngIf="!availableUsers?.length">
    <i>No recipients available.</i>
  </ng-container>
</div>
<div class="modal-footer">
    <div class="col">
        <button type="button" class="btn btn-primary pull-left" (click)="onClickAddNewFeedbackRecipient()"><span class="fa fa-plus"></span> Add New</button>
      </div>
      <div class="col">
        <span class="pull-right">
          <button type="button" class="btn btn-cancel" (click)="onDismiss()"><span class="fa fa-ban"></span> Cancel</button>
          <button type="button" class="btn btn-primary" (click)="onClose()" [disabled]="!hasSelectedUsers"><span class="fa fa-save"></span> Submit</button>
      </span>
      </div>
 
 
</div>
