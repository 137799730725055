<div class="modal-header">
  <h4 class="modal-title">Recipient of Assessment Results</h4>
  <button type="button" class="close" aria-label="Close" (click)="onDismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <table class="table table-striped table-sm">
    <tr>
      <th>Name</th>
      <th>Proxy Report</th>
    </tr>
    <tr *ngFor="let user of filteredUsers | quickSearch: _search">
      <td>{{user.name}}</td>
      <td class="text-center">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox" [(ngModel)]="user.proxy">
          </label>
        </div>
      </td>
    </tr>
  </table>
  <ng-container *ngIf="!filteredUsers?.length">
    <i>No users available.</i>
  </ng-container>
</div>
<div class="modal-footer">
  <div class="col">
        <span class="pull-right">
            <button type="button" class="btn btn-primary" (click)="onClose()"><span class="fa fa-plus"></span> Submit</button>
            <button type="button" class="btn btn-cancel" (click)="onDismiss()"><span class="fa fa-ban"></span> Cancel</button>
      </span>
  </div>
</div>
