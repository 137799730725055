import {Component, OnInit, ViewEncapsulation, ViewChild, ElementRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray} from '@angular/forms';
import {ISelectItemModel, IUserSelectItemModel, IFeedbackUserSelectItem} from '../../core/interfaces/select-item.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {EditAssessmentRequestModel} from '../edit-assessment-request.model';
import {AssessmentRequestService} from '../../core/assessment-request.service';
import {IPositionListItemModel} from '../../core/interfaces/position-list-item.interface';
import {IAssessmentLevelReferenceModel} from '../../core/interfaces/assessment-level-reference.interface';
import {IAdditionalServiceReferenceModel} from '../../core/interfaces/additional-service-reference.interface';
import {IEditAssessmentRequestModel} from '../edit-assessment-request.interface';
import {IOrgUnitModel} from '../../core/interfaces/org-unit.interface';
import {IUserBillingContactModel} from '../../core/interfaces/billing-contact.interface';
import {PositionService} from '../../core/position.service';
import {AuthService} from '../../auth/auth.service';
import {ClientSection} from './sections/client.section';
import {CandidateSection} from './sections/candidate.section';
import {AssessmentPurposeSection} from './sections/assessment-purpose.section';
import {PositionCompetenciesSection} from './sections/position-competencies.section';
import {ServicesRequestedSection} from './sections/services-requested.section';
import {LocationSection} from './sections/location.section';
import {FeedbackSection} from './sections/feedback.section';
import {BillingContactSection} from './sections/billing-contact.section';
import {ICanDeactivateComponent} from '../../shared/can-deactivate-component.guard';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IClientSettingsModel} from '../../core/interfaces/client-settings.interface';
import {ClientService} from '../../core/client.service';
import {AppService} from '../../core/app.service';
import {isNullOrUndefined, isString} from 'util';
import {Subject, Observable} from 'rxjs';
import { debounceTime } from "rxjs/operators";
import {AttachmentService} from '../../core/attachment.service';
import {AssessmentRequestStatus} from '../../core/enums/assessment-request-status.enum';
import {FeedbackUserService} from '../../core/feedback-user.service';
import {FormInvalidComponent} from './form-invalid.component';
import { MatAutocomplete } from '@angular/material/autocomplete';
import {FormControl} from '@angular/forms';
import {Utility} from '../../core/utility';
import {DuplicateCandidateDialogComponent} from '../duplicate-candidate-dialog/duplicate-candidate-dialog.component';
import {ConfirmNoResumeDialogComponent} from '../confirm-no-resume-dialog/confirm-no-resume-dialog.component';
import {UserService} from '../../core';
import {IDuplicateCandidateInfo} from '../../core/interfaces/duplicate-candidate.interface';
import {AdditionServiceDuplicateDialogComponent} from './additional-service-duplicate-dialog/additional-service-duplicate-dialog.component';
import { UploadErrorDialogComponent } from 'app/shared/upload-error-dialog/upload-error-dialog.component';
import { IEditPositionModel } from 'app/core/interfaces/edit-position.interface';

type IPositionChange = (position: IPositionListItemModel) => void;

@Component({
  selector: 'edit-assessment-request',
  templateUrl: './edit-assessment-request.component.html',
  styleUrls: ['edit-assessment-request.component.scss'],
  providers: [
    AssessmentPurposeSection,
    BillingContactSection,
    ClientSection,
    CandidateSection,
    FeedbackSection,
    LocationSection,
    PositionCompetenciesSection,
    ServicesRequestedSection
  ],
  encapsulation: ViewEncapsulation.None
})
export class EditAssessmentRequestComponent implements OnInit, ICanDeactivateComponent {
  public assessmentRequest: EditAssessmentRequestModel;
  public clients: ISelectItemModel[];
  public users: IUserSelectItemModel[];
  public languages: ISelectItemModel[];
  public assessmentLevelReferences: IAssessmentLevelReferenceModel[];
  public additionalServiceReferences: IAdditionalServiceReferenceModel[];
  public states: ISelectItemModel[];
  public countries: ISelectItemModel[];
  public settings: IClientSettingsModel;
  public form: UntypedFormGroup;
  public alertMessage: string;
  public successMessage: string;
  private _modal: NgbModalRef;
  private _saveStatus = new Subject<string>();
  private _saveSuccess = new Subject<string>();
  private preventChooseFirstOption: boolean = false;
  private routeLock: boolean = true;
  private dataLock: boolean = true;
  private syncLock: boolean = false;
  private copyAuthFlag: boolean = false;
  private data: any;
  public upgrade: boolean = false;
  public newDevFeedback: boolean = false;
  public originalAssessmentLevelOrderNumber: number;
  public originalAssessmentLevel: number;
  public disableSave: boolean = false;
  public disableVerbal: boolean = false;
  public disableWritten: boolean = false;
  public previousConfidentialState: boolean = null;
  public confidentialOptions: string[] = ['Executive-level position',
    'Open position is not posted',
    'Position currently filled',
    'Internal candidate that desires discretion',
    'Position is related to Finance Department',
    'Position is related to HR Department',
    'Limit access to own scores if hired'];
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;
  @ViewChild('orgUnitSelect') orgUnitSelect: ElementRef;
  @ViewChild('resumeInput') resumeInput: ElementRef;
  _duplicateCandidates: IDuplicateCandidateInfo[];

  constructor(private fb: UntypedFormBuilder,
              private authService: AuthService,
              private assessmentRequestService: AssessmentRequestService,
              private clientService: ClientService,
              private feedBackUserService: FeedbackUserService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal,
              private appService: AppService,
              private positionService: PositionService,
              public assessmentPurposeSection: AssessmentPurposeSection,
              public billingContactSection: BillingContactSection,
              public clientSection: ClientSection,
              public candidateSection: CandidateSection,
              public feedbackSection: FeedbackSection,
              public locationSection: LocationSection,
              public positionCompetenciesSection: PositionCompetenciesSection,
              public servicesRequestedSection: ServicesRequestedSection,
              public user: UserService,
              private route: ActivatedRoute,
              private AttachmentService: AttachmentService) {
    this.route.url.subscribe(params => {
      //Is this a copy?
      if(params[0] 
        != null && params[0].path != null){
        if (params[0].path == "copy"){
          this.copyAuthFlag = true;
        }
      }
      //Is this an Upgrade?
      if(params[1] != null && params[1].path != null){
        if (params[1].path == "upgrade-request"){
          this.upgrade = true;
        } 
        if (params[1].path == "development-feedback") {
          this.newDevFeedback = true;
        }
      }
      this.routeLock = false;
      this.initSync();
    })
  }

  // fix safari 12 and edge 15, 16, 17 bug
  ngAfterViewChecked(): void {
    if (this.orgUnitSelect && this.clientSection.orgUnitControl && !this.clientSection.orgUnitControl.value) {
      this.orgUnitSelect.nativeElement.selectedIndex = -1;
    }
  }

  filteredOptions: IPositionListItemModel[]; // Observable<IPositionListItemModel[]>;

  displayPositionFn(position?: IPositionListItemModel): string | undefined {
    var output = position ? position.description : undefined;
    //Unable to output HTML, leave a space for the icon to be conditionally displayed by the template.
    if(position && position.isConfidential)
      output = "   " + output;
    return output;
  }

  filter(val: string): IPositionListItemModel[] {
    return this.positions.filter(IPositionListItemModel =>
      IPositionListItemModel.description.toLowerCase().indexOf(val.toLowerCase()) !== -1);
  }

  public chooseFirstOption(event: Event): void {
    if(!this.preventChooseFirstOption){
      if(this.filteredOptions.length > 0)
      {
        this.matAutocomplete.options.first.select();
      }
      else
        this.assessmentPurposeSection.positionControl.setValue(""); 
      this.preventChooseFirstOption = false;
    }
  }
  public get disableClientEdit() : boolean{
    if(this.isAdmin)
      return (!this.assessmentRequest.isNew || this.assessmentRequest.hasParent);
    else
      return true;
  }
  
  public onPositionsClick(trigger)
  {
      this.preventChooseFirstOption = false;
      // if(!this.matAutocomplete.showPanel)
      //   this.matAutocomplete.showPanel = true;
      if(this.matAutocomplete.options.first)
        this.matAutocomplete.options.first.setInactiveStyles();
      trigger.openPanel();
      this.filteredOptions = this.positions;
  }

  public onArrowKey()
  {
    //Okay, so because Material autocomplete is missing a lot of functionality one might expect... we are forced to implement it.
    this.preventChooseFirstOption = true;
  }

  public onPositionBlur()
  {
    setTimeout(() => {
    //Validate selection
    if(!this.assessmentPurposeSection.positionControl.value || !this.assessmentPurposeSection.positionControl.value.positionId){
      //if position select is not valid, clear the field.
      
        this.assessmentPurposeSection.positionControl.setValue(null);
        
      }
    }, 500)
  }
  
  public positionSelected(position: IPositionListItemModel){
     // retrieve the competencies for the newly selected position
     this.positionService.getCompetencies(position.positionId)
     .subscribe((competencies) => {
       if (competencies && competencies.length) {
         this.positionCompetenciesSection.competenciesControl.setValue(competencies);
       }else{
         this.positionCompetenciesSection.competenciesControl.setValue([]);
       }
       this.positionCompetenciesSection.competenciesControl.markAsDirty();
       //We need competencies to have been loaded so do this here.
       this.handlePositionSelected(position);
     });
  }

  private handlePositionSelected(selectedPosition: IPositionListItemModel){
    if (selectedPosition.isConfidential){
      if(!this.assessmentRequest.isConfidential){
        //Then we need to offer to set the AR confidential
        this._modal = this.modalService.open(ConfirmationDialogComponent); 
        const instance = this._modal.componentInstance as ConfirmationDialogComponent;
        instance.title = 'Make Request Confidential';
        instance.body = 'You have selected a confidential position for a non-confidential request. Would you like to make this request confidential? (Yes will hide this record from everyone else in your organization.)';//'This is a confidential request, a matching confidential position description will be created by copying the current position. This cannot be undone.';
        instance.onConfirm.subscribe((isConfirmed) => {
          if (isConfirmed) {
            this.assessmentRequest.isConfidential = true;
          } else {
            this.assessmentPurposeSection.positionControl.setValue(""); 
          }
        });
      }
    } else {
      if(this.assessmentRequest.isConfidential){
        //Then we need to offer to create a confidential copy of the position
        this._modal = this.modalService.open(ConfirmationDialogComponent); 
        const instance = this._modal.componentInstance as ConfirmationDialogComponent;
        instance.title = 'Create a Confidential Position';
        instance.body = 'This Assessment Request has been marked confidential. You have selected a Position profile that is not confidential. Would you like to use the details of the selected position to make a confidential Position profile for this Request?';//'This is a confidential request, a matching confidential position description will be created by copying the current position. This cannot be undone.';
        instance.onConfirm.subscribe((isConfirmed) => {
          if (isConfirmed) {
            //From here we need to pull up the Add Position Modal, Copy fields from the existing position, and setting it to Confidential.
            this.createConfidentialPositionCopy((pos: IPositionListItemModel) => { 
              if(pos != null){
              //Confidential Position was created; go ahead and set AR confidential.
              this.assessmentRequest.isConfidential = true;
              } else {
                this.assessmentPurposeSection.positionControl.setValue(""); 
              }
            });
          } else {
            //User refused to create a confidential copy of their selected position - remove selection.
            this.assessmentPurposeSection.positionControl.setValue(""); 
          }
        });
      } 
    }
  }

  public onConfidential(){
    //Initially, confirm the user even wants to make this position confidential.
    this._modal = this.modalService.open(ConfirmationDialogComponent);
    const instance = this._modal.componentInstance as ConfirmationDialogComponent;
    instance.title = this.assessmentRequest.isConfidential ? 'Disable Confidentiality' : 'Make Confidential';
    instance.body = this.assessmentRequest.isConfidential ? `Are you sure you want to disable Confidentiality for this Request?` : 'You have selected to make this record Confidential. It will not be visible to anyone else in your organization. You will need to create a confidential position profile which will not be visible to anyone else in your organization. Would you like to make this record confidential?';
    instance.onConfirm.subscribe((isConfirmed) => {
      if (isConfirmed) {
        //Let's split this into Enabling and Disabling Confidentiality.
        if(!this.assessmentRequest.isConfidential){
          if(this.assessmentPurposeSection.positionControl.value == null || this.assessmentPurposeSection.positionControl.value == ''){
            //Let's do it.
            this.assessmentRequest.isConfidential = true;
            this.setConfidentialFeedbackRecipient();
            return;
          }
          if(!this.assessmentPurposeSection.positionControl.value.isConfidential){
            //Then we need to offer to create a confidential copy of the position if it is not already confidential
            this._modal = this.modalService.open(ConfirmationDialogComponent); 
            const instance = this._modal.componentInstance as ConfirmationDialogComponent;
            instance.title = 'Create a Confidential Position';
            instance.body = 'This Assessment Request has been marked confidential. You have selected a Position profile that is not confidential. Would you like to use the details of the selected position to make a confidential Position profile for this Request?';//'This is a confidential request, a matching confidential position description will be created by copying the current position. This cannot be undone.';
            instance.onConfirm.subscribe((isConfirmed) => {
              if (isConfirmed) {
                //From here we need to pull up the Add Position Modal, Copy fields from the existing position, and setting it to Confidential.
                this.createConfidentialPositionCopy((pos: IPositionListItemModel) => { 
                  if(pos != null){
                    //Confidential Position was created; go ahead and set AR confidential.
                    this.assessmentRequest.isConfidential = true;
                    this.setConfidentialFeedbackRecipient();
                  } else {
                    this.assessmentPurposeSection.positionControl.setValue(""); 
                  }
                });
              } else {
                this.assessmentPurposeSection.positionControl.setValue(""); 
              }
            });
          } 
        } else {
          //This is here to allow the user disable again before the save.
          if(this.previousConfidentialState != true || this.isAdmin){
            if(this.assessmentPurposeSection.positionControl.value != null && this.assessmentPurposeSection.positionControl.value.isConfidential)
              this.assessmentPurposeSection.positionControl.setValue(""); 
            this.assessmentRequest.isConfidential = false;
          }
        }
      }    
    });
  }

  public setConfidentialFeedbackRecipient(){
    this.feedBackUserService.getAvailable(this.requestedByUser.id).subscribe((data: IFeedbackUserSelectItem[]) => {
      data.forEach(x => {
        if(x.userId == this.requestedByUser.id){
          x.verbal = true;
          this.feedbackSection.feedbackUsersControl.setValue(Array(x));
        }
      });
    });
  }

  public selectFirstOption(event: Event) {

    // if(this.matAutocomplete == null || this.matAutocomplete.options == null)
    //   return;
    // //The up and down arrow events are intercepted so this style gets stuck on...
    // if(this.filteredOptions.length > 1) setTimeout(() => {
    //     this.matAutocomplete.options.forEach(function (option) {
    //       option.setInactiveStyles();
    //     });
    // }, 100)
    // if(this.filteredOptions.length > 0){
    //   setTimeout(() => { this.matAutocomplete.options.first.setActiveStyles(); }, 250);   
    // }
  }

  public get client(): ISelectItemModel {
    return this.clientSection.clientControl.value;
  }

  public get orgUnit(): IOrgUnitModel {
    return this.clientSection.orgUnit;
  }

  public get requestedByUser(): IUserSelectItemModel {
    return this.clientSection.requestedByUserControl.value;
  }

  public get billingContact(): IUserBillingContactModel {
    return this.billingContactSection.billingContactControl.value;
  }

  public get clientUsers(): ISelectItemModel[] {
    return this.client
      ? this.users.filter((u) => u.clientId === this.client.id)
      : [];
  }

  public get orgUnits(): IOrgUnitModel[] {
    return this.clientSection.orgUnits;
  }

  public get positions(): IPositionListItemModel[] {
    const orgUnitId = this.orgUnit ? this.orgUnit.orgUnitId : null;
    var selectedPositionId = 0;
    if(this.assessmentPurposeSection.positionControl.value != null)
      selectedPositionId = this.assessmentPurposeSection.positionControl.value.positionId;
    // JSG: Added check for position orgunit is null to include positions not assigned to a department in filter.
    return this.assessmentPurposeSection.positions.filter((p) => (p.orgUnitId === orgUnitId || p.orgUnitId === null) &&
     p.positionId != selectedPositionId);
  }

  public get isAdmin(): boolean {
    return this.authService.isRoleAdmin;
  }

  public get isAdminWithImpersonation(): boolean {
    return this.authService.roleName == "Admin";
  }

  public get userLevel(): number {
    return this.authService.userLevel;
  }

  public get disableConfidentialButton(): boolean {
    return (this.previousConfidentialState || this.userLevel == 1) && !this.isAdminWithImpersonation;
  }

  public get formValues(): IEditAssessmentRequestModel {
    const updatedModel = Object.assign({},
      this.assessmentRequest,
      this.clientSection.formValue,
      this.candidateSection.formValue,
      this.assessmentPurposeSection.formValue,
      this.positionCompetenciesSection.formValue,
      this.servicesRequestedSection.formValue,
      this.locationSection.formValue,
      this.feedbackSection.formValue,
      this.billingContactSection.formValue) as IEditAssessmentRequestModel;

    updatedModel.candidate = Object.assign({}, this.assessmentRequest.candidate, updatedModel.candidate);
    if(this.previousConfidentialState != this.assessmentRequest.isConfidential)
      updatedModel.isConfidential = this.assessmentRequest.isConfidential;
    return updatedModel;
  }

  public get sortedFormValues(): IEditAssessmentRequestModel {
    const unsortedFormValues = this.formValues;
    const sortedFormValues = {} as IEditAssessmentRequestModel;

    Object.keys(unsortedFormValues).sort().forEach(function (key) {
      sortedFormValues[key] = unsortedFormValues[key];
    });

    return sortedFormValues;
  }

  public initSync()
  {
    if (!this.routeLock && !this.dataLock && !this.syncLock) {
      //I'm guessing this still doesn't prevent all concurency issues... hopefully close.
      this.syncLock = true;
      this.init(this.data);
      if(this.copyAuthFlag){
        this.assessmentRequest.assessmentRequestId = null;
        this.assessmentRequest.assessmentId = null;
        this.assessmentRequest.candidate.candidateId = null;
        this.assessmentRequest.statusId = 1;
        this.clientSection.costCenterControl.setValue(null);
        this.clientSection.generalLedgerControl.setValue(null);
        this.clientSection.activityCenterControl.setValue(null);
        this.clientSection.customCandidateIdControl.setValue(null);
        this.clientSection.customPoControl.setValue(null);
        this.clientSection.costCenterOwnerControl.setValue(null);
        this.clientSection.companyCodeControl.setValue(null);
        //clear candidate 
        this.candidateSection.clear();
        this.servicesRequestedSection.setCopy();
        this.assessmentPurposeSection.purposeControl.setValue(''); 
      }
    }
  }

  private init(data: any){
    this.clients = data.clients || [];
    this.users = data.users || [];
    this.languages = data.languages || [];
    //We need to copy (slice) this array because based on settings.enableLevelZero, we may remove options and the original array is sometimes cached.
    this.assessmentLevelReferences = data.assessmentLevelReferences.slice() || [];
    this.additionalServiceReferences = data.additionalServiceReferences || [];
    this.states = data.states || [];
    this.countries = data.countries || [];  
    this.previousConfidentialState = data.assessmentRequest.isConfidential;
    const ar: EditAssessmentRequestModel = data.assessmentRequest;
    this.assessmentRequest = ar;
    if(this.copyAuthFlag){
      this.assessmentRequest.consultantUserId = null;
      if(this.authService.impersonatedUser != null)
        this.assessmentRequest.requestedByUserId = this.authService.impersonatedUser.userId; 
    }

    if (this.assessmentRequest.isNew) {
      this.initializeForCurrentUser(ar);
      if(this.upgrade){
        this.assessmentRequest.additionalServiceReferenceIds.push(4);
        //Cache the order number for the original selected assessmentLevel -- we use this to prevent low levels from being displayed for upgrades.
        this.originalAssessmentLevelOrderNumber = this.assessmentLevelReferences.filter(x => x.assessmentLevelReferenceId == this.assessmentRequest.assessmentLevelReferenceId)[0].orderNumber;
        this.originalAssessmentLevel = this.assessmentRequest.assessmentLevelReferenceId;
        this.assessmentRequest.assessmentLevelReferenceId = null;
        this.assessmentRequest.statusId = 0;
      }
      if(this.newDevFeedback){
        //TODO: move these status resets out to just if isNew. This is a hotfix and I want to limit possible regression.
        this.assessmentRequest.statusId = 0;
        this.assessmentRequest.orginalAdditionalServiceReferenceIds.forEach((refId) => {
          switch(refId){
            case 1: {
              this.disableWritten = true;
              break;
            }
            case 2: {
              this.disableVerbal = true;
            }
            default: {
              break;
            }
          }
        });
        if(this.disableVerbal || this.disableWritten){
          this._modal = this.modalService.open(AdditionServiceDuplicateDialogComponent);
          const instance = this._modal.componentInstance as AdditionServiceDuplicateDialogComponent;
          instance.disableVerbal = this.disableVerbal;
          instance.disableWritten = this.disableWritten;
        }
      }

    } else if (this.assessmentRequest.clientSettings) {
      this.settings = this.assessmentRequest.clientSettings;
      this.initializeLevelReferences();
    }

    //special initialization handling for already assigned additional services.
    this.assessmentRequest.additionalServiceReferenceIds.forEach((refId) => {
      switch(refId){
        case 4: {
          this.upgrade = true;
          break;
        }
        default: {
          break;
        }
      }
    });

   

    this.form = this.buildForm();
    if (this.assessmentRequest.isNew){
      if (this.newDevFeedback){
        // Reset Phone & E-Mail for External Candidates on new DevFeedback.
        if(this.candidateSection.typeControl.value === 'E') { 
          this.candidateSection.workPhoneControl.setValue('');
          this.candidateSection.emailControl.setValue('');
        }
        this.candidateSection.typeControl.setValue('I');
      }
    }

    this.appService.onLoggingOut.subscribe(() => {
      if (!this._modal) {
        return;
      }
      this._modal.dismiss('logging out');
    });


    this.form.get('assessmentPurpose.position').valueChanges.subscribe(change => {
      if(isString(change) && change != "-1"){
        this.filteredOptions = this.filter(change);
        this.selectFirstOption(null);
      }else{
        this.filteredOptions = this.positions;
        this.selectFirstOption(null);
      }
    });
  }

  public ngOnInit() {
    this.activatedRoute.data.subscribe((data: any) => {
      this.data = data;
      this.dataLock = false;
      this.initSync();
    });

    // // this.filteredOptions = this.positions;
    // this._saveStatus.subscribe((message) => this.alertMessage = message);
    // debounceTime.call(this._saveStatus, 60000).subscribe(() => this.alertMessage = null);

    // this._saveSuccess.subscribe((message) => this.successMessage = message);
    // debounceTime.call(this._saveSuccess, 2000).subscribe(() => this.successMessage = null);
  }

  public get enoughToSave() {
    // not a new request, returning
    if (!this.assessmentRequest.isNew && this.assessmentRequest.statusId === AssessmentRequestStatus.NotSubmitted) {
      return true;
    }

    if (this.assessmentRequest.isNew
      && this.client
      && this.formValues.candidate.lastName
      && (this.orgUnits.length === 0 || this.orgUnits.length > 0 && this.orgUnit)) {
      return true;
    }

    return false;
  }

  public canDeactivate() {
    if (this.form.touched && this.form.dirty && this.authService.loggedInUser) {
      this._modal = this.modalService.open(ConfirmationDialogComponent);

      const instance = this._modal.componentInstance as ConfirmationDialogComponent;
      instance.title = 'Close Assessment Request';
      instance.body = 'Are you sure you want to exit? Unsaved data will be lost.';

      return instance.onConfirm;
    }

    return true;
  }

  public get canEditDepartment(): boolean {
    if(this.isAdmin && this.authService.impersonatedUser == null)
      return true;

    if (!this.hasDepartments() || this.hasOnlyOneDepartment()) {
      return false;
    }

    // if (!this.assessmentRequest.isNew && this.orgUnit) {
    //   return false;
    // }

    return true;
  }

  public hasDepartments(): boolean {
    if (isNullOrUndefined(this.orgUnits) || this.orgUnits.length === 0) {
      return false;
    }
    return true;
  }

  //Oops. This does more than just check if there's only one department, doesn't it.
  public hasOnlyOneDepartment(): boolean {
    if (!isNullOrUndefined(this.orgUnit) && this.orgUnits.length === 1) {
      return true;
    }
    return false;
  }

  public onClickAddPosition(copy: IEditPositionModel, confidential: boolean, onPositionAdded: IPositionChange) {
    if(this.assessmentRequest.isConfidential){
      this.addPosition(copy, true, onPositionAdded);
    } else {
      this.addPosition(copy, this.assessmentRequest.isConfidential ? true : false, onPositionAdded);
    }
  }
  
  private addPosition(copy: IEditPositionModel, confidential: boolean, onPositionAdded: IPositionChange){
    const clientId = this.client.id;
    const orgUnitId = this.orgUnit ? this.orgUnit.orgUnitId : null;
    this.assessmentPurposeSection.showAddPositionDialog(clientId, orgUnitId, this.orgUnits.map(function (unit) {
      return {id: unit.orgUnitId, name: unit.name, selected: null};
    }), (pos: IPositionListItemModel) => {
        this.assessmentPurposeSection.positionControl.setValue(pos);
        this.assessmentPurposeSection.positionControl.markAsDirty();

        if (pos.customCompetency) {
          this.positionCompetenciesSection.customCompetenciesControl.setValue(pos.customCompetency);
          this.positionCompetenciesSection.customCompetenciesControl.markAsDirty();
        }
        if(onPositionAdded != null){
          onPositionAdded(pos);
        }
        this.positionSelected(pos);
    }, true, copy, confidential);
  }

  public createConfidentialPositionCopy(onPositionAdded: IPositionChange){
    if( this.assessmentPurposeSection.positionControl.value != null){
      var position: IEditPositionModel = this.assessmentPurposeSection.positionControl.value;
      position.competencies = this.positionCompetenciesSection.competenciesControl.value;
      position.customCompetency = this.assessmentRequest.customCompetencies;
      this.onClickAddPosition(position, true, onPositionAdded); 
    }
  }

  public onClickEditCompetencies() {
    this._modal = this.modalService.open(UploadErrorDialogComponent);
    const instance = this._modal.componentInstance as UploadErrorDialogComponent;
    instance.title = 'Changes Only Apply Here';
    instance.body = `The changes you are making will only effect this candidate. If you would like to change the competencies for this position, please go the the Positions Tab to edit the competencies, or contact us at clientservices@leadershipall.com or 314-993-8008.`;

    instance.onConfirm.subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.user.getUserForEdit(this.requestedByUser.id).subscribe(x => {
          this.positionCompetenciesSection.onClickEditCompetencies(x.clientId);
        });
      }
    });
  }

  public onClickAddBillingContact() {
    this.user.getUserForEdit(this.requestedByUser.id).subscribe(x => {
      this.billingContactSection.onClickAddBillingContact(x, this.canEditDepartment?this.clientSection.orgUnit.name:"Corporate");
    });
    
  }

  public onClickEditBillingContact() {
    this.user.getUserForEdit(this.requestedByUser.id).subscribe(x => {
      this.billingContactSection.onClickEditBillingContact(x);
    });
  }

  public getAssessmentLevelSampleReportUrl(assessmentLevelReference: IAssessmentLevelReferenceModel): string {
    return `api/assessment-level-reference/${assessmentLevelReference.assessmentLevelReferenceId}/sample`;
  }

  public getAdditionalServiceSampleReportUrl(additionalServiceReference: IAdditionalServiceReferenceModel): string {
    return `api/additional-service-reference/${additionalServiceReference.additionalServiceReferenceId}/sample`;
  }

  public onUploadResumeFileChange(event) {
    this.candidateSection.onUploadResumeFileChange(event);
  }

  public onClickAddExistingFeedbackRecipient() {
    this.feedbackSection.showAddExistingFeedbackUserDialog(this.requestedByUser);
  }

  public onClickAddNewFeedbackRecipient() {
    this.feedbackSection.onClickAddNewFeedbackRecipient(this.requestedByUser);
  }

  public get additionalServiceReferencesArray() {
    return this.servicesRequestedSection.additionalServiceReferencesArray as UntypedFormArray;
  }

  public get allowSaveAdditionalService(): boolean {
    return this.form && this.additionalServiceReferencesArray.value.some((r: boolean) => r);
  }

  public get isNewAdditionalService()
  {
    return (this.assessmentRequest.hasParent && this.assessmentRequest.isNew);
  }

  public get isAdditionalService()
  {
    return (this.assessmentRequest.additionalService == 1 && this.assessmentRequest.hasParent)
  }

  public onSave() {
    this.disableSave = true;
    //If this isn't a NEW additional service request - SaveProgress.
    if(!this.isNewAdditionalService){
      this.assessmentRequestService.saveProgress(this.formValues).subscribe(() => {
        this.form.markAsUntouched();
        this.form.markAsPristine();
        this.disableSave = true;
        this.router.navigate(['/assessment-requests']);
      },
      (error) => {this._saveStatus.next('Save operation failed: ' + error); this.disableSave = false;});
    } else { //Special handling for NEW additional service requests.
      if(this.newDevFeedback){
        this.assessmentPurposeSection.purposeControl.setValue(2);
      }
      setTimeout(() => {
        //setValue (above) appears to run Async with nothing to subsribe to...
        this.onSubmit();
      }, 250);

    }
  }

  //setTimeout 30 => Check Server for created, if not found, warn and save again, if found proceed.

  public onSubmit() {
    if (!this.form) { // This condition prevented submission of valid form until a field had been touched: || !this.form.dirty) {
      return;
    }

    if(this.assessmentRequest.isConfidential){
      this._modal = this.modalService.open(ConfirmationDialogComponent); 
      var billingContact = this.billingContactSection.billingContactControl.value ? this.billingContactSection.billingContactControl.value.displayName : 'the selected contact';
      const instance = this._modal.componentInstance as ConfirmationDialogComponent;
      instance.title = 'Confidential Record Billing';
      instance.body = `You have created a record that is Confidential. Billing for this record will be sent to ` + billingContact + `. The name of the candidate will be removed from the invoice, but the services ordered will remain. Please contact us at 314-993-8008 or at clientservices@leadershipall.com with any questions.`;
      instance.affirmativeButtonText = "Submit";
      instance.negativeButtonText = "Go Back";
      instance.onConfirm.subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.missingResumeCheck();
        } else {
        return;
        }
      });
    } else {
      this.missingResumeCheck();
    }

    
  }

  private missingResumeCheck(){
    if(!this.candidateSection.noResumeControl.value && !this.candidateSection.resumeAttachmentIdControl.value){
      this._modal = this.modalService.open(ConfirmNoResumeDialogComponent);
      const instance = this._modal.componentInstance as ConfirmNoResumeDialogComponent;
      instance.Yes.subscribe(() => {
        this.resumeInput.nativeElement.click();
      });
      instance.NotNow.subscribe(() => {
        this.submit();
      });
      instance.No.subscribe(() => {
        this.candidateSection.noResumeControl.setValue(true);
        this.submit();
      });
    } else {
      this.submit();
    }
  }

  private submit(){
    //We need to touch the control now because feedbackSecion.isInvalid is bypassed if the control has not been touched.
    //This is possibly a deeper issue where some of these controls have two versions of validator code; feedback has been unified.
    this.feedbackSection.touchAllControls();
    //                    ****Goofy condition related to feedback section. Also TODO: can we just use isAdditionalService here? Or is that getter not valid for new DFs?
    if (this.form.invalid || (!(this.newDevFeedback || this.isAdditionalService) && this.feedbackSection.isInvalid)) {

      this._modal = this.modalService.open(FormInvalidComponent, {size: 'lg'});
      const instance = this._modal.componentInstance as FormInvalidComponent;

      this.assessmentPurposeSection.touchAllControls();
      this.billingContactSection.touchAllControls();
      this.clientSection.touchAllControls();
      this.candidateSection.touchAllControls();
      this.feedbackSection.touchAllControls();
      this.locationSection.touchAllControls();
      this.positionCompetenciesSection.touchAllControls();
      this.servicesRequestedSection.touchAllControls();
      this.disableSave = false;
      return;
    }
    this.disableSave = true;
    this.assessmentRequestService.save(this.formValues).subscribe(() => {
      this.form.markAsUntouched();
      this.form.markAsPristine();
      this.router.navigate(['/assessment-requests']);
      //this.disableSave = false;
    },
      (error) => {this._saveStatus.next('Save operation failed: ' + error); this.disableSave = false;});
  }

  public onCancel() {
    this.router.navigate(['assessment-requests']);
  }

  public get needTestingInstructions() {
    return this.form.get('location.testingLocation').value === 1
  }

  private buildForm(): UntypedFormGroup {
    this.clientSection.init({
      assessmentRequest: this.assessmentRequest,
      clients: this.clients,
      users: this.users,
      onClientChange: this.onClientChange.bind(this),
      onOrgUnitChange: this.onOrgUnitChange.bind(this),
      onRequestedByChange: this.onRequestedByChange.bind(this),
      onOrgUnitInit: this.onOrgUnitInit.bind(this)
    });

    this.candidateSection.init({
      assessmentRequest: this.assessmentRequest,
      languages: this.languages
    });

    this.positionCompetenciesSection.init({
      assessmentRequest: this.assessmentRequest,
      servicesRequestedSection: this.servicesRequestedSection
    });

    this.assessmentPurposeSection.init({
      assessmentRequest: this.assessmentRequest,
      onPositionChange: this.positionCompetenciesSection.onPositionChange.bind(this.positionCompetenciesSection),
      onPositionDescriptionUpload: this.onPositionDescriptionUpload.bind(this)
    });

    this.servicesRequestedSection.init({
      assessmentRequest: this.assessmentRequest,
      assessmentLevelReferences: this.assessmentLevelReferences,
      additionalServiceReferences: this.additionalServiceReferences 
    });

    this.locationSection.init({
      assessmentRequest: this.assessmentRequest
    });

    this.feedbackSection.init({
      assessmentRequest: this.assessmentRequest,
      states: this.states,
      countries: this.countries,
      onDefaultsAdded: this.onDefaultsAdded.bind(this),
      servicesRequestedSection: this.servicesRequestedSection
    });

    this.billingContactSection.init({
      assessmentRequest: this.assessmentRequest,
      states: this.states,
      countries: this.countries
    });

    return this.fb.group({
      client: this.clientSection.formGroup,
      candidate: this.candidateSection.formGroup,
      positionCompetencies: this.positionCompetenciesSection.formGroup,
      assessmentPurpose: this.assessmentPurposeSection.formGroup,
      servicesRequested: this.servicesRequestedSection.formGroup,
      location: this.locationSection.formGroup,
      feedback: this.feedbackSection.formGroup,
      billingContact: this.billingContactSection.formGroup
    });
  }

  private onClientChange(newClient: ISelectItemModel) {
    if (newClient) {
      this.assessmentPurposeSection.onClientChange(newClient.id);
      this.positionCompetenciesSection.competenciesControl.setValue([]);
      this.feedbackSection.feedbackUsersControl.setValue([]);

      const clientUsers = this.clientUsers;
      if (clientUsers.length) {
        this.clientSection.requestedByUserControl.setValue(clientUsers[0]);
      }

      this.clientService.getSettings(newClient.id).subscribe((settings) => {
        this.settings = settings
        this.initializeLevelReferences();
      });
      this.feedbackSection.clientId = newClient.id;
    }
  }

  // This appears to do nothing.
  private onOrgUnitChange() {
    if (!this.assessmentRequest.isNew) {
      return;
    }
    if (this.hasDepartments() && isNullOrUndefined(this.orgUnit)) {
      return;
    }
  }

  //Callback that, for non admins, attempts to select an orgunit if there's only one (but not if it's not a new AR)
  private onOrgUnitInit(){
    if (this.assessmentRequest.isNew && (this.authService.isRoleClient || this.authService.impersonatedUser != null) ){
      if(!this.upgrade && !this.newDevFeedback)       
          if(this.orgUnits.length === 1 )
            this.clientSection.orgUnitControl.setValue(this.orgUnits[0].orgUnitId);
      }
      
  }

  private loadFeedBackDefaults() {
    const ownerUserId = isNullOrUndefined(this.requestedByUser) ? null : this.requestedByUser.id;
    const $feedBackDefaults = this.feedBackUserService.getDefaults(this.client.id, ownerUserId);
    
    $feedBackDefaults.subscribe(data => {
      this.feedbackSection.feedbackUsersControl.setValue(data);
    });
  }

  private onRequestedByChange(newRequestedByUser: IUserSelectItemModel): void {
    if (!newRequestedByUser) {
      this.billingContactSection.billingContactControl.disable();
      return;
    }
    this.billingContactSection.retrieveBillingContacts(newRequestedByUser.id, true);
    this.feedbackSection.feedbackUsersControl.setValue([]);
    this.feedbackSection.requestedByUserId = newRequestedByUser.id;
    if(!this.assessmentRequest.isConfidential)
      this.loadFeedBackDefaults();
  }

  private initializeForCurrentUser(ar: EditAssessmentRequestModel) {
    // set initial values for users in the client role
    if (this.authService.isRoleClient && this.authService.clientId) {
      ar.clientId = this.authService.clientId;
      ar.requestedByUserId = this.authService.userId;
      
      const $feedBackDefaults = this.feedBackUserService.getDefaults(this.authService.clientId, this.authService.userId);
      const orgUnitIds = this.authService.orgUnitIds;

      this.clientService.getSettings(ar.clientId).subscribe((settings) => {
        this.settings = settings
        this.initializeLevelReferences();
      });

      $feedBackDefaults.subscribe(data => {
        this.feedbackSection.feedbackUsersControl.setValue(data);
      });

      // if (orgUnitIds && orgUnitIds.length) {
      //   //ar.orgUnitId = orgUnitIds[0];
      // }
    }
  }

  private initializeLevelReferences(){
    if(this.settings){
      var assembledLevels = new Array();
      this.assessmentLevelReferences.forEach(x => {
        if(x.assessmentLevelReferenceId == 5 && !this.settings.enableLevelZero){
          return;
        }
        if(x.assessmentLevelReferenceId == 6 && !this.settings.enableLevel1S){
          return;
        }
        assembledLevels.push(x);
      });
      this.assessmentLevelReferences = assembledLevels;
    }
  }

  private onDefaultsAdded(message: string) {
    this._saveSuccess.next(message);
  }

  public duplicateCandidateCheck() {
    // check if email has been entered
    if (!Utility.isBlank(this.candidateSection.emailControl.value)) {
      // Now check if fields were actually changed and not just touched.
      if (this.candidateSection.emailControl.dirty && this.assessmentRequest.candidate.email != this.candidateSection.emailControl.value) {
        // check for duplicate candidate and open modal if one exists
        this.user.getDuplicateCandidateInfo(this.client.id, this.candidateSection.emailControl.value)
          .subscribe((data) => {
            if (data.length > 0) {
              this._modal = this.modalService.open(DuplicateCandidateDialogComponent, {size: 'lg'});
              this._modal.componentInstance.duplicateCandidates = data;
            }
          });
      }
    } else {
      return;
    }
  }

  public isLevelZeroRequest(){
    if(this.servicesRequestedSection)
      return this.servicesRequestedSection.formValue.assessmentLevelReferenceId == 5
    else
      return null;
  }
  //public additionalServiceDisable(control, additionalService) {
  //  switch(additionalService.additionalServiceReferenceId){
  //    //Upgrade should only be avilable for Upgrade ARs
  //    case 4: {
  //      if(this.upgrade){
  //        //control.enable();
  //        //and it should not be editable for new Upgrades (They must be flagged with the Upgrade additional service)
  //        //The control must be enabled to contain a value.
  //        if(this.isNewAdditionalService){
  //          return true;
  //        }
  //      }
  //      else
  //        control.disable();
  //    }
  //    default: {
  //      if (additionalService.isDevelopment && this.candidateSection.typeControl.value === 'E') {
  //        control.disable();
  //      } else if (additionalService.isDevelopment) {
  //        control.enable();
  //      }
  //    }
  //  }
  //}

  public additionalServiceToolTipText(additionalServiceReference) : string {
    let output = (this.additionalServiceDisabled(additionalServiceReference) && additionalServiceReference.additionalServiceReferenceId != 4) ? 'Service only available for Current Employees (Internal). Once hired, please add additional services.' : '';
    switch (additionalServiceReference.additionalServiceReferenceId){
      case 1: {
        if(this.disableWritten){
          output = 'Written Development Feedback has already been requested - Please see original request.'
        }
      }
      case 2: {
        if(this.disableVerbal){
          output = 'Verbal Development Feedback has already been requested - Please see original request.'
        }
      }
    }
    
    return output;
  }

  public additionalServiceDisabled(additionalService) : boolean {
    switch(additionalService.additionalServiceReferenceId){
      case 1: {
        return (additionalService.isDevelopment && this.candidateSection.typeControl.value === 'E') || this.disableWritten;
      }
      case 2: {
        return (additionalService.isDevelopment && this.candidateSection.typeControl.value === 'E') || this.disableVerbal;
      }
      case 3: {
        return (this.isNewAdditionalService || this.assessmentRequest.additionalService == 1 || this.upgrade); 
      }
      case 4: {
        return true; //this.isNewAdditionalService;
      }
    
    }
  }

  public assessmentLevelDisplayed(assessmentLevelReferenceId, orderNumber) : boolean {
    if(this.isNewAdditionalService && this.upgrade){
      //Development Assessment is not an upgrade option.
      if(assessmentLevelReferenceId == 4){
        return false;
      }
      //Snapshot is not a valid upgrade from a Flash Report.
      if(this.originalAssessmentLevel == 6 && assessmentLevelReferenceId == 1){
        return false;
      }
      return orderNumber > this.originalAssessmentLevelOrderNumber;
    }else{
      return true;
    }
  }

  public assessmentLevelEnabledToolTip(assessmentLevelReferenceId){
    switch(assessmentLevelReferenceId){
      case 1: {
        return "Comprehensive assessment, Simplified One-Page Report.";
      }
      case 2: {
        return "Comprehensive Assessment, Custom narrative report and follow-up questions.";
      }
      case 3: {
        return "Comprehensive Assessment plus 90-minute interview with our Ph.D. consultant, In-depth Custom narrative report with additional interview insights and follow-up questions.";
      }
      case 4: {
        return "Comprehensive Assessment battery and 90-minute interview with our consultant, In-depth custom narrative report with additional insight for organization; plus 90-minute coaching insights meeting and development report for individual.";
      }
      case 6: {
        return "Abbreviated assessment measuring problem solving and core competencies.";
      }
    }
  }

  public assessmentLevelDisabled(assessmentLevelReferenceId){
    if (this.candidateSection.typeControl.value == 'E' && assessmentLevelReferenceId == 4) {
      return "This service is for current employees. Please select a High-Definition Report instead.";
    }
    if (this.candidateSection.typeControl.value == 'I' && this.assessmentPurposeSection.purposeControl.value == 4 && assessmentLevelReferenceId == 4) {
      return "For internal candidates for the purpose of promotion or promotion and development, please select High-Definition Report and then add additional services of Verbal and Written Development Feedback for Employee.";
    }
    if(this.upgrade && assessmentLevelReferenceId == 5){
      return "This service is not available for upgrades.";
    }
    if(assessmentLevelReferenceId == 5 && (this.candidateSection.typeControl.value == 'E' || this.assessmentPurposeSection.purposeControl.value == 4)){
      return "Hogan Only are for Development Purposes only and cannot be used for External Candidates.";
    }
    //else
    return null;
  }

  private onPositionDescriptionUpload(positionId, attachmentId, fileName) {
    for (let position of this.positions) {
      if (position.positionId === positionId) {
        position.jobDescriptionAttachmentId = attachmentId;
        position.jobDescriptionFileName = fileName;
        this.filteredOptions = this.positions;
        break;
      }
    }
  }

  public ensureAssessmentLevel(){
    //Ensure that assessmentLevel is not left with an invalid selection.
    if(this.candidateSection.typeControl.value === 'E' && this.servicesRequestedSection.formValue.assessmentLevelReferenceId == 4){
      this.servicesRequestedSection.assessmentLevelReferenceControl.setValue(null);
    }

    if(this.candidateSection.typeControl.value === 'I' && this.assessmentPurposeSection.purposeControl.value == 4 && this.servicesRequestedSection.formValue.assessmentLevelReferenceId == 4){
      this.servicesRequestedSection.assessmentLevelReferenceControl.setValue(null);
    }
  }
}
