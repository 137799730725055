import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {AssessmentRequestService} from '../../core/assessment-request.service';
import {LocalDataSource} from '../../piquant-table/data/local-datasource';
import {ITableAction, ITableSettings, Row} from '../../piquant-table/models';
import {AssessmentRequestListItemModel} from '../assessment-request-list-item.model';
import {format} from 'date-fns';
import {AuthService} from '../../auth/auth.service';
import {AssessmentRequestStatusGroup} from '../assessment-request-status-group';
import {DateFilter} from '../../piquant-table/models/date-filter';
import {SiteUtilityService, UserService, AttachmentService} from '../../core';
import {AssessmentRequestStatus} from '../../core/enums/assessment-request-status.enum';
import {AssessmentRequestPermissionsService} from '../assessment-request-permissions.service';
import {DeleteAssessmentRequestDialogComponent} from '../delete-assessment-request-dialog/delete-assessment-request-dialog.component';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SecureFileDownload} from '../../core/secure-file-download.service';
import {AssignConsultantComponent} from '../../shared/assign-consultant/assign-consultant.component';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';
import {Subscription} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {SelectAdditionalServiceTypeDialogComponent} from '../additional-services/select-additional-service-type-dialog/select-additional-service-type-dialog.component';
import {AppService} from '../../core/app.service';
import {GridOptions, RowNode, Column, SelectionChangedEvent} from "ag-grid-community";
import {ViewButtonRendererComponent} from '../view-button-renderer/view-button-renderer.component';
import { DetailCellRendererComponent } from '../detail-cell-renderer/detail-cell-renderer.component';
import 'ag-grid-enterprise';
import {RendererActionBarComponent} from '../renderer-action-bar/renderer-action-bar.component';
import {FilterGridStatusComponent} from '../filter-grid-status/filter-grid-status.component';
import { HiringOutcomePollModalComponent } from 'app/assessment-requests/hiring-outcome-poll-modal/hiring-outcome-poll-modal.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FilterHiringOutcomeComponent} from '../filter-hiring-outcome/filter-hiring-outcome.component';
import {ProxyReportDialogComponent} from '../proxy-report-dialog/proxy-report-dialog.component';
import {SetStatusDialogComponent} from '../set-status-dialog/set-status-dialog.component';
import {AssessmentRequestProxyService} from '../../core/assessment-request-proxy.service';
import { ManageMfaModalComponent } from '../manage-mfa-modal/manage-mfa-modal.component';
import { EditUserModel } from '../../users/edit-user.model';
import {Location} from '@angular/common';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {UploadErrorDialogComponent} from '../../shared/upload-error-dialog/upload-error-dialog.component';
import {OutOfOfficeService} from '../../core/out-of-office.service';
import { IOutOfOfficeModel } from 'app/core/interfaces/out-of-office.interface';

@Component({
  selector: 'list-assessment-request',
  templateUrl: './list-assessment-request.component.html',
  providers: [SiteUtilityService],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class ListAssessmentRequestComponent implements OnInit, OnDestroy
{
  public psTableSettings: ITableSettings;
  public assessmentRequests: LocalDataSource = new LocalDataSource();
  public assessmentRequestList : AssessmentRequestListItemModel[];
  public statuses = AssessmentRequestStatusGroup.List;
  private listRefreshSub: Subscription;
  private _modal: NgbModalRef;
  public gridOptions: GridOptions;
  public selectedNode: string;
  private rendererEvents: [number, (data: boolean) => void];
  public model: any;
  public inputQuickSearch: string;
  public selectedPaginationOption = 2;
  private showHiringOutcomeModal: boolean = false;
  private showManageMfaModal: boolean = false;
  public paginationOptions = [
    {id: 0, name: "10"},
    {id: 1, name: "50"},
    {id: 2, name: "100"},
    {id: 3, name: "500"}
  ];
  public hideNotHired: boolean = false;
  public user: EditUserModel;
  private previousNodeSelection: RowNode = null;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private siteUtility: SiteUtilityService,
              private assessmentRequestPermissions: AssessmentRequestPermissionsService,
              private modalService: NgbModal,
              private fileDownloadService: SecureFileDownload,
              private assessmentRequestService: AssessmentRequestService,
              private appService: AppService,
              public dialog: MatDialog,
              private proxyService: AssessmentRequestProxyService,
              private userService: UserService,
              private location: Location,
              private attachmentService: AttachmentService,
              private oooService: OutOfOfficeService)
  {
    this.route.url.subscribe(params => {
      if(params[0] != null && params[0].path != null){
        if (params[0].path == "hiring-outcome"){
          this.showHiringOutcomeModal = true;
        }
        //We need the modal to be able to trigger a reload.
        if (params[0].path == "exit-offer-modal"){
          this.location.replaceState('/assessment-requests');
        }
        if(params[0].path == "manage-mfa"){
          //suppress hiring outcome modal
          this.showHiringOutcomeModal = false;
          this.showManageMfaModal = true;
        }
      }
    })
    this.gridOptions = <GridOptions>
    {
      masterDetail: true,
      detailCellRenderer: DetailCellRendererComponent,
      columnDefs: this.createColumnDefs(),
      rowHeight: 48, // recommended row height for material design data grids,
      headerHeight: 38,
      rowBuffer: 5, // This is set very low right now because of poor performance for the ngb Popover workaround in place. Default is 20
      animateRows: true,
      pagination: true,
      rowSelection: "single",
      suppressCellFocus: true,
      suppressMenuHide: true,
      getRowClass: (event) => this.rowBackgroundColor(event),
      onRowClicked: (event) => this.onSelectionChanged(event),
      onFilterChanged: (event) => this.onFilterChanged(event),
      onSortChanged: () => this.onColumnChanged(),
      onColumnResized: () => this.onColumnChanged(),
      context: { componentParent: this },   // Make this component (and its members) available to child components.
      detailRowHeight: (100+(14/((window.innerWidth-263)/150))*35),
      defaultColDef: {
        resizable: true,
        sortable: true
      }
    }
  }

  onResize(event) {
    localStorage.setItem('windowWidth', JSON.stringify(event.target.innerWidth));
    this.gridOptions.api.sizeColumnsToFit();
  }

  public exportDataAsCsv(){
    this.gridOptions.api.exportDataAsCsv();
  }

  public display360Info(){
    this._modal = this.modalService.open(ConfirmationDialogComponent);
    const instance = this._modal.componentInstance as ConfirmationDialogComponent;
    instance.title = '360-degree Feedback Request';
    instance.body = `If you would like more information about our 360-Degree Feedback for your employees, please reach out to our Client Services team. You can reach them at 314-993-8008 or clientservices@ccoleadership.com. Alternatively, choose “Contact Me” below to send us an email and we will reach out to you with more information.`;
    instance.affirmativeButtonText = 'Contact Me';
    instance.negativeButtonText = 'Close';
    instance.onConfirm.subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.assessmentRequestService.send360InfoNotification().subscribe(() => {
          this._modal = this.modalService.open(UploadErrorDialogComponent);
          const instance = this._modal.componentInstance as UploadErrorDialogComponent;
          instance.title = 'We\'ll be in touch!';
          instance.body = 'Your request has been sent, we will contact you soon.';
        });
      }
    });
  }

  private displayHiringOutcomeModal() {
    // this._modal = this.modalService.open(HiringOutcomePollModalComponent);
    // const instance = this._modal.componentInstance as HiringOutcomePollModalComponent;
    // instance.assessmentRequests = this.assessmentRequestList;
    let dialogRef = this.dialog.open(HiringOutcomePollModalComponent, {
      width: '85%',
      height: '85%',
      data: { assessmentRequestList: this.assessmentRequestList, manualModal: this.showHiringOutcomeModal, admin: this.authService.isRoleAdmin }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(this.showHiringOutcomeModal)
        this.router.navigate(['assessment-requests']);
    });
  }

  private displayManageMfaModal() {
    let dialogRef = this.dialog.open(ManageMfaModalComponent, {
      width: '85%',
      height: '85%',
      data: { userData: this.user }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(this.showManageMfaModal)
        this.router.navigate(['assessment-requests']);
    });
  }


  public onChangeHideNotHired(event){
    const hideNotHired = event.target.checked;
    this.hideNotHired = hideNotHired;
    var currentFilters = this.gridOptions.api.getFilterModel();
    currentFilters['0'] = {pending: true, outcomeHired: true, outcomeNotHired: !hideNotHired, showUnanswered: true};
    this.gridOptions.api.setFilterModel(currentFilters);
    //localStorage.setItem('gridARHideNotHired'+this.getImpersonatedUserId(), this.hideNotHired?"1":"0");
  }

  public rowBackgroundColor(event) : string{
    if (event.node.rowIndex % 2 === 0) {
      return 'shaded-row';
    }
    else
      return null;
  }

  public setPageSize(paginationOption)
  {
    //Current version of AG Grid appears to have problems with autopagination (at least if it's turned on at runtime)... disabling.
    // if(paginationOption != 0)
    // {
      this.gridOptions.paginationAutoPageSize = false;
      this.gridOptions.api.paginationSetPageSize(Number( (this.paginationOptions[paginationOption]) ? this.paginationOptions[paginationOption].name : 100));
    // }
    // else
    // {
    //   this.gridOptions.paginationAutoPageSize = true;
    //   //this.gridOptions.api.doLayout();
    //   this.gridOptions.api.redrawRows();
    // }
  }

  public onFilterTextBoxChanged() {
      this.gridOptions.api.setQuickFilter(this.inputQuickSearch);
  }

  public resetFilters()
  {
    //localStorage.setItem('gridARHideNotHired'+this.getImpersonatedUserId(), "1");
    //Reset Pagination Option
    this.onChangePaginationOption(2);
    //each column has to have filters removed.
    for (let column of this.gridOptions.columnApi.getAllColumns())
    {
      this.gridOptions.api.destroyFilter(column);
    }
    // Now cleanup the quick search -> reset the input element
    this.inputQuickSearch = '';
    // and reset the grid quickfilter
    this.gridOptions.api.setQuickFilter('');
    // and set the default status filter
    this.gridOptions.api.setFilterModel(this.getDefaultFilter());
    // reset the default sort.
    this.gridOptions.columnApi.resetColumnState();
    // finaly raise the events...
    this.gridOptions.api.onSortChanged();
    this.gridOptions.api.onFilterChanged();
    this.gridOptions.api.sizeColumnsToFit();
        // Reset "Show NotHired"
    this.hideNotHired = false;
  }

  public onColumnChanged() {
    localStorage.setItem('gridColumnState'+this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.columnApi.getColumnState())); //get/applyColumnState()
  }

  public onFilterChanged(event) {
    localStorage.setItem('gridARFilterModel'+this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.api.getFilterModel()));
  }

  public onChangePaginationOption(option)
  {
    this.selectedPaginationOption = option; // event.target.value;
    this.setPageSize(this.selectedPaginationOption);
    localStorage.setItem('gridARPagination'+this.getImpersonatedUserId(), this.selectedPaginationOption.toString());
  }

  public getImpersonatedUserId(): string
  {
    return (this.authService.impersonatedUser != null) ? this.authService.impersonatedUser.userId.toString() : this.authService.userId.toString();
  }

  public onSelectionChanged(event)
  {
    var selectedNode: RowNode = event.node;
    if(this.previousNodeSelection != null){
      //Colapse the open detail if one was open
      this.previousNodeSelection.setExpanded(false);
      if(this.previousNodeSelection == selectedNode){
        //Deselect if the user clicked on the same node
        selectedNode.setSelected(false);
        selectedNode = null;
      }
    }
    
    //If there's still a selected node, expand it
    if(selectedNode != null){
      selectedNode.setExpanded(true);
    }
    //and cache it as the previous selection.
    this.previousNodeSelection = selectedNode;
  }

  public ngOnInit(): void {
    this.appService.onLoggingOut.subscribe(() => {
      if (!this._modal) {
        return;
      }
      this._modal.dismiss('logging out');
    });

      this.route.data.subscribe((data: any) => {
        this.user = data.user as EditUserModel;
      });
  }

  public ngAfterViewInit()
  {
    this.route.data.subscribe(({assessmentRequests}: { assessmentRequests: AssessmentRequestListItemModel[] }) => {
      this.gridOptions.api.setRowData(assessmentRequests);
      this.assessmentRequests.load(assessmentRequests);
      this.assessmentRequestList = assessmentRequests;
      //this.gridOptions.api.doLayout();
      //We want to supress the hiring outcome modal if we came here to show the ManageMFA Modal.
      if(this.showManageMfaModal){
        this.displayManageMfaModal();
      } else {
        // Let's not run the modal trigger work if we came here (by router path) to present the modal.
        if(this.showHiringOutcomeModal)
          this.displayHiringOutcomeModal();
        else
          this.HandleHiringOutcomeModal();
      }
    });

    // This is a neat idea but I've only seen one place where it's used.
    this.listRefreshSub = this.assessmentRequestService.onRefreshList$.subscribe(refresh => {
      if (refresh) {
        this.assessmentRequestService.getAll([]).subscribe(data => { this.gridOptions.api.setRowData(data); }); //this.assessmentRequests.load(data));
        this.loadGridPreferences();
        // this.gridOptions.api.setRowData(this.createRowData());
      }
    });

    this.loadGridPreferences();

    this.resetFilters();

  }

  public HandleHiringOutcomeModal()
  {
    try{
      if(this.authService.impersonatedUser != null){
        //Currently there is no desire for Admins to see this modal while impersonating. There are issues to address before this can be allowed.
        return;
      }

      //Should we show the outcome poll modal?
      //We want this in the data.subscribe callback to ensure we have our data for the modal grid...
      //We store a local time to support skipping the dialog for a period of time (on the local machine) without updating the lastoutcomepolldate on the server.
      var nextOutcomeModalTime = new Date(localStorage.getItem('gridARnextOutcomeModalTime'+this.getImpersonatedUserId()));
      if(nextOutcomeModalTime < new Date() || isNaN(nextOutcomeModalTime.getTime()) )
      {
        //Note: nextOutcomeModaltime stores the NEXT time the modal should appear (local)
        //      And the lastOutcomePollDate (server) stores the LAST time the user answered the poll.

        //We'll use this date to confirm that we have an AR that was created long enough ago to poll on it.
        var earlyDateThreshold = new Date();
        earlyDateThreshold.setDate(earlyDateThreshold.getDate()-14);//setDate(thirtyDaysAgo.getDate()-2);
        //We'll use this date to make sure that they aren't so old we aren't asking about them anymore.
        var lateDateTheshold = new Date(2018,0,1);
        //lateDateTheshold.setDate(lateDateTheshold.getDate()-60);
        //We'll use this (along with the server provided lastOutcomePollDate) to ensure users are not asked more often than the throttle no matter what.
        var globalModalPollThrottle = new Date();
        globalModalPollThrottle.setDate(globalModalPollThrottle.getDate()-7);
        //So even though the ARList date is already a date type, valueof returns a JSON/ISOString instead of ms ... they can't be compared - fun stuff.
          //If the lastPollDate is less than the globalModalPollThrottle, present the modal.
        if(this.authService.lastOutcomePollDate < globalModalPollThrottle || isNaN(this.authService.lastOutcomePollDate.getDate())){
          //Next we need to know if there actually are any ARs that need answers.
          for (var i = 0, len = this.assessmentRequestList.length; i < len; i++) {
            if(this.assessmentRequestList[i].hiringOutcome < 2 || !this.assessmentRequestList[i].hiringOutcome){
              var lastStatusChangeDate: Date = new Date(this.assessmentRequestList[i].lastStatusChangeDate);
              if(this.assessmentRequestList[i].statusId == AssessmentRequestStatus.Complete &&
                this.assessmentRequestList[i].candidateType == "E" &&
                this.assessmentRequestList[i].parentAssessmentRequestId == null &&
                this.assessmentRequestList[i].requestedByUserId == this.authService.loggedInUser.userId &&
                lastStatusChangeDate.valueOf() < earlyDateThreshold.valueOf() && //thirtyDaysAgo &&
                lastStatusChangeDate.valueOf() > lateDateTheshold.valueOf()
                ) {
                  nextOutcomeModalTime = new Date();
                  nextOutcomeModalTime.setTime(nextOutcomeModalTime.getTime()+1800000); //30 min in milliseconds.
                  //This can be used as a failsafe to throttle modals if a user is having trouble or otherwise not dismissing the modal.
                  localStorage.setItem('gridARnextOutcomeModalTime'+this.getImpersonatedUserId(), nextOutcomeModalTime.toString());
                  this.displayHiringOutcomeModal();
                  break;
              }
            }
            //else: nothing to see here.
          }
        }
      }
    }
    catch(err){
    }
  }

  public ngOnDestroy() {
    this.listRefreshSub.unsubscribe();
    this.listRefreshSub = null;
  }

  public loadGridPreferences()
  {   
    //Handle loading saved Sort, Filter, and Column settings.
    var columnState = localStorage.getItem('gridColumnState'+this.getImpersonatedUserId());  //gridColumnState'+this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.columnApi.getColumnState()
    if(columnState != null){
      this.gridOptions.columnApi.applyColumnState(JSON.parse(columnState));
      //Prevent poor column saved states when a user's window size changes between sessions.
      if(+localStorage.getItem('windowWidth') != window.innerWidth){
        this.gridOptions.api.sizeColumnsToFit();
        localStorage.setItem('windowWidth', JSON.stringify(window.innerWidth));
      }
    }
    else{
      this.gridOptions.api.sizeColumnsToFit();
      this.resetFilters();
    }
    var filterModel = localStorage.getItem('gridARFilterModel'+this.getImpersonatedUserId());
    if(filterModel != null){
      const thisModel = JSON.parse(filterModel);
      this.gridOptions.api.setFilterModel(thisModel);
      if(thisModel['0'] != null && thisModel['0'].outcomeNotHired != null)
        this.hideNotHired = !thisModel['0'].outcomeNotHired;
    //  var hideNotHiredStorage = localStorage.getItem('gridARHideNotHired'+this.getImpersonatedUserId());
    //  if(hideNotHiredStorage != null || hideNotHiredStorage != "")
    //    this.hideNotHired = hideNotHiredStorage=="1" ? true : false;
    //   this.gridOptions.api.setFilterModel({0:{ARGridMode: true, pending: true, outcomeHired: true, outcomeNotHired: this.showNotHired, showAlreadyAnswered: true}});
    }
    else
      this.resetFilters();
    //Also attempt to load pagination selection
    var paginationOption = localStorage.getItem('gridARPagination'+this.getImpersonatedUserId());
    if(paginationOption != null)
      this.onChangePaginationOption(Number(paginationOption));
  }

  private createColumnDefs() {
    return [
      {
        headerName: "HiddenFilter",
        filterFramework: FilterHiringOutcomeComponent,
        hide: true
      },
      {
        headerName: "Candidate",
        field: "candidateName",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        valueFormatter: "value",
        cellRendererFramework: ViewButtonRendererComponent,
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Client",
        field: "clientName",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        hide: this.isRoleClient,
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Department",
        field: "orgUnitName",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Requested By",
        field: "requestedByName",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Consultant",
        field: "consultantName",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        suppressMenu: false,
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Position",
        field: "position",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Status",
        field: "statusName",
        filterFramework: FilterGridStatusComponent,
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-arrow-up"/>',
          sortDescending: '<i class="fa fa-arrow-down"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Submitted Date",
        field: "submittedDate",
        filter: "agDateColumnFilter",
        menuTabs: ['filterMenuTab'],
        valueFormatter: this.dateCellFormatter,
        sort: 'desc',
        icons: {
          sortAscending: '<i class="fa fa-arrow-up"/>',
          sortDescending: '<i class="fa fa-arrow-down"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      }
    ];
  }

  private createRowData() {
    //if(this.gridOptions != null)
      //this.gridOptions.columnApi.setColumnVisible("Client", !this.isRoleClient);
    return this.assessmentRequestList;
  }

  //Move this to shared?
  private dateCellFormatter(params) {

      return `${(params.value.getMonth() + 1)}/${params.value.getDate()}/${params.value.getFullYear()}`;

  }

  public newAssessmentClick() {
    this.oooService.CheckOOO().subscribe((data: any) => {
      const outOfOfficeData = data as IOutOfOfficeModel;
      if(outOfOfficeData.isOutOfOffice){
        this._modal = this.modalService.open(ConfirmationDialogComponent);
        const instance = this._modal.componentInstance as ConfirmationDialogComponent;
        instance.title = outOfOfficeData.title;
        instance.body = outOfOfficeData.message;
        instance.affirmativeButtonText = 'Okay';
        instance.negativeButtonText = 'Close';
        instance.onConfirm.subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.router.navigate(['assessment-requests', 'add']);
        }
    });
      } else {
        this.router.navigate(['assessment-requests', 'add']);
      }
    });

  }

  public get isRoleClient() {
    return this.authService.isRoleClient;
  }

  private getDefaultFilter() { //AssessmentRequestStatus[] {
    return this.isRoleClient
      ? {0: {ARGridMode: true, pending: true, outcomeHired: true, outcomeNotHired: true, showAlreadyAnswered: true}, statusName: {notSubmitted: true, submitted: true, inProcess: true, complete: true, inProcessReportsUploaded: true, inProcessTestingStarted: true, inProcessTestingComplete: true}}
      : {0: {ARGridMode: true, pending: true, outcomeHired: true, outcomeNotHired: true, showAlreadyAnswered: true}, statusName: {submitted: true, inProcess: true, inProcessReportsUploaded: true, inProcessTestingStarted: true, inProcessTestingComplete: true}};
  }
  private buildTableActions(): ITableAction[] {
    return [
      {
        name: 'View',
        action: (row: any) => {
          //const assessmentRequest = this.getAssessmentRequest();
          this.router.navigate(['/assessment-requests/view', row.assessmentRequestId]);
        },
        iconClass: 'fa fa-eye',
        actionClass: 'btn btn-link',
        isVisible: !this.siteUtility.isMobileWidth,
        disabled: (row: any) => {return false;},
        isRowVisible: (row: any) => {
          return true;
        }
      },
      {
        name: 'Edit',
        action: (row: any) => {
          //const assessmentRequest = this.getAssessmentRequest();
          this.router.navigate(['/assessment-requests/edit', row.assessmentRequestId]);
        },
        iconClass: 'fa fa-edit',
        actionClass: 'btn btn-link',
        isVisible: !this.siteUtility.isMobileWidth,
        disabled: (row: any) => {return false;},
        isRowVisible: (row: any) => {
          //const status = this.getAssessmentRequestStatus(row);
          const status = row.statusId as AssessmentRequestStatus;
          return this.assessmentRequestPermissions.allowEdit(status);
        }
      },
      {
        name: 'Copy',
        action: (row: any) => {
          //const assessmentRequest = this.getAssessmentRequest();
          this.router.navigate(['/assessment-requests/copy', row.assessmentRequestId]);
        },
        iconClass: 'fa fa-clone',
        actionClass: 'btn btn-link',
        isVisible: !this.siteUtility.isMobileWidth,
        disabled: (row: any) => {return ((row.requestedByUserId != this.authService.user.userId && !this.authService.isRoleAdmin)||row.isConfidential);},
        isRowVisible: (row: any) => {
          return true;
        }
      },
      {
        name: 'Manage Consultant',
        action: (row: any) => {
          const ar = row;
          const options: NgbModalOptions = {backdrop: 'static', keyboard: false};
          this._modal = this.modalService.open(AssignConsultantComponent, options);

          const instance = this._modal.componentInstance as AssignConsultantComponent;
          instance.assessmentRequestInfo = {id: ar.assessmentRequestId, name: ar.candidateName, assignedConsultantName: ar.consultantName};

          instance.assigned.subscribe((consultant: ISelectItemModel) => {
            this.assessmentRequestService.clearCache();
            this.assessmentRequestService.getAll([]).subscribe((data) => this.gridOptions.api.setRowData(data));
          });
        },
        iconClass: 'fa fa-user-plus',
        actionClass: 'btn btn-link',
        isVisible: !this.siteUtility.isMobileWidth,
        disabled: (row: any) => {return false;},
        isRowVisible: (row: any) => {
          const status = row.statusId as AssessmentRequestStatus;
          return this.assessmentRequestPermissions.allowAssignConsultant(status);
        }
      },
      {
        name: 'Testing Schedule',
        action: (row: any) => {
          this.router.navigate(['/assessment-requests/testing-schedule', row.assessmentRequestId, row.candidateName]);
        },
        iconClass: 'fa fa-calendar-o',
        actionClass: 'btn btn-link',
        isVisible: !this.siteUtility.isMobileWidth,
        disabled: (row: any) => {return false;},
        isRowVisible: (row: any) => {
          const status = row.statusId as AssessmentRequestStatus;
          return this.assessmentRequestPermissions.allowTestingSchedule(status);
        }
      },
      {
        name: 'Additional Service',
        action: (row: any) => {
          //const assessmentRequest = this.getAssessmentRequest(row);
          this.showCreateAdditionalServiceDialog(row);
        },
        iconClass: 'fa fa-plus',
        actionClass: 'btn btn-link',
        isVisible: !this.siteUtility.isMobileWidth,
        disabled: (row: any) => {return false;},
        isRowVisible: (row: any) => {
          const status = row.statusId as AssessmentRequestStatus;
          return this.assessmentRequestPermissions.allowAdditionalServices(status, row.parentAssessmentRequestId);;

        }
      },
      {
        name: 'Set Status',
        action: (row: any) => {
          this.showSetStatusDialog(row);
        },
        iconClass: 'fa fa-tags',
        actionClass: 'btn btn-link',
        isVisible: !this.siteUtility.isMobileWidth,
        disabled: (row: any) => {return false;},
        isRowVisible: (row: any) => { return this.assessmentRequestPermissions.isAdmin(); }
      },
      {
        name: 'Download Assessment Request',
        action: (row: any) => {
          //const assessmentRequest = this.getAssessmentRequest(row);
          this.fileDownloadService.DownloadAssessmentRequest(row.assessmentRequestId)
            .subscribe((data) => this.handleDownload(data));
        },
        iconClass: 'fa fa-cloud-download',
        actionClass: 'btn btn-link',
        isVisible: !this.siteUtility.isMobileWidth,
        disabled: (row: any) => {return !this.assessmentRequestPermissions.allowDownloadAssessmentRequest(row.statusId as AssessmentRequestStatus, true);},
        isRowVisible: (row: any) => {
          return this.authService.isRoleAdmin || this.assessmentRequestPermissions.allowDownloadAssessmentRequest(row.statusId as AssessmentRequestStatus, true);
        }
      },
      {
        name: 'Download Job Description',
        action: (row: any) => {
          if (row.noJobDescription === true || row.jobDescriptionAttachmentId === null || row.jobDescriptionAttachmentId === 0) {
            alert('No job description is available');
            return;
          }
          this.attachmentService.download(row.jobDescriptionAttachmentId);
        },
        iconClass: 'fa fa-cloud-download',
        actionClass: 'btn btn-link',
        isVisible: !this.siteUtility.isMobileWidth,
        disabled: (row: any) => {
          return (row.noJobDescription === true || row.jobDescriptionAttachmentId === null || row.jobDescriptionAttachmentId === 0)
        },
        isRowVisible: (row: any) => {
          return this.assessmentRequestPermissions.allowDownloadJobDescription();
        },

      },
      {
        name: 'Download Resume',
        action: (row: any) => {
          if (row.candidateNoResume === true || row.resumeAttachmentId === null || row.resumeAttachmentId === 0) {
            alert('No Resume available');
            return;
          }
          this.attachmentService.download(row.resumeAttachmentId);
        },
        iconClass: 'fa fa-cloud-download',
        actionClass: 'btn btn-link',
        isVisible: !this.siteUtility.isMobileWidth,
        disabled: (row: any) => {return !this.assessmentRequestPermissions.allowDownloadAssessmentRequest(row.statusId as AssessmentRequestStatus, true) || (row.candidateNoResume === true || row.resumeAttachmentId === null || row.resumeAttachmentId === 0);},
        isRowVisible: (row: any) => { //!(row.candidateNoResume === true || row.resumeAttachmentId === null || row.resumeAttachmentId === 0)
          //const status = this.getAssessmentRequestStatus(row);
          //const status = row.statusId as AssessmentRequestStatus;
          return this.authService.isRoleAdmin || this.assessmentRequestPermissions.allowDownloadAssessmentRequest(row.statusId as AssessmentRequestStatus, true);
        }
      },
      {
        name: 'Upload Reports',
        action: (row: any) => {
          //const assessmentRequest = this.getAssessmentRequest(row);
          this.router.navigate(['/assessment-requests/upload-reports', row.assessmentRequestId]);
        },
        iconClass: 'fa fa-cloud-upload',
        actionClass: 'btn btn-link',
        isVisible: !this.siteUtility.isMobileWidth,
        disabled: (row: any) => {return !this.assessmentRequestPermissions.allowUploadReports(row.statusId as AssessmentRequestStatus);},
        isRowVisible: (row: any) => { return this.assessmentRequestPermissions.isAdmin(); }
      },
      {
        name: 'Download Reports',
        action: (row: any) => {
          //const assessmentRequest = this.getAssessmentRequest(row);
          this.router.navigate(['/assessment-requests/download-reports', row.assessmentRequestId]);
        },
        iconClass: 'fa fa-cloud-download',
        actionClass: 'btn btn-link',
        disabled: (row: any) => {return !this.assessmentRequestPermissions.allowDownloadReports(row.statusId as AssessmentRequestStatus, row.reportsUploaded > 0);},
        isRowVisible: (row: any) => { return !(this.authService.userLevel === 1 && !this.authService.isRoleAdmin); }
      },
      {
        name: 'Proxy Report',
        action: (row: any) => {
          this.showProxyReportDialog(row);
        },
        iconClass: 'fa fa-share',
        actionClass: 'btn btn-link',
        disabled: (row: any) => {return false;},
        isRowVisible: (row: any) => { return this.assessmentRequestPermissions.isAdmin(); }
      },
      {
        name: 'Delete',
        action: (row: any) => {
          //const assessmentRequest = this.getAssessmentRequest(row);
          this.showDeleteConfirmationDialog(row);
        },
        actionClass: 'text-danger btn btn-link',
        iconClass: 'fa fa-trash-o',
        isVisible: !this.siteUtility.isMobileWidth,
        disabled: (row: any) => {return false;},
        isRowVisible: (row: any) => {
          //const status = this.getAssessmentRequestStatus(row);
          const status = row.statusId as AssessmentRequestStatus;
          return this.assessmentRequestPermissions.allowDelete(status);
        }
      }
    ];
  }

  private handleDownload(data: Blob) {
    const url = window.URL.createObjectURL(data);
    window.open(url);
  }

  private getAssessmentRequestStatus(row: Row): AssessmentRequestStatus {
    const assessmentRequest = this.getAssessmentRequest(row);
    return assessmentRequest.statusId as AssessmentRequestStatus;
  }

  private getAssessmentRequest(row: Row): AssessmentRequestListItemModel {
    return row.getData() as AssessmentRequestListItemModel;
  }

  private showDeleteConfirmationDialog(assessmentRequest: AssessmentRequestListItemModel) {
    this._modal = this.modalService.open(DeleteAssessmentRequestDialogComponent);

    const instance = this._modal.componentInstance as DeleteAssessmentRequestDialogComponent;
    instance.assessmentRequestId = assessmentRequest.assessmentRequestId;
    instance.candidateName = assessmentRequest.candidateName;

    instance.onDelete.subscribe(() => {
      this.assessmentRequestService.clearCache();
      this.assessmentRequestService.getAll([]).subscribe((data) => this.gridOptions.api.setRowData(data));
    });
  }

  private showCreateAdditionalServiceDialog(assessmentRequest: AssessmentRequestListItemModel) {
    this._modal = this.modalService.open(SelectAdditionalServiceTypeDialogComponent);

    const instance = this._modal.componentInstance as SelectAdditionalServiceTypeDialogComponent;
    instance.assessmentRequestId = assessmentRequest.assessmentRequestId;
    instance.candidateName = assessmentRequest.candidateName;
  }

  private showSetStatusDialog(assessmentRequest: AssessmentRequestListItemModel) {
    this._modal = this.modalService.open(SetStatusDialogComponent);

    const instance = this._modal.componentInstance as SetStatusDialogComponent;
    instance.assessmentRequest = assessmentRequest;
    instance.onUpdate.subscribe((statusId: any) => {
      //assessmentRequest.statusId = statusId;
      this.assessmentRequestService.clearCache();
      this.assessmentRequestService.getAll([]).subscribe((data) => this.gridOptions.api.setRowData(data));
    });
  }

  private showProxyReportDialog(assessmentRequest: AssessmentRequestListItemModel) {
    this.proxyService.getProxyUsers(+assessmentRequest.assessmentRequestId).subscribe(
      results => {
        results.forEach((user) => {
          user.name = user.first + ' ' + user.last;
        });

        this.userService.getUsersByClientId(+assessmentRequest.clientId, true).subscribe(
          users => {
            users.forEach((user: any) => {
              user.proxy = false;
            });
            this._modal = this.modalService.open(ProxyReportDialogComponent);
            const instance = this._modal.componentInstance as ProxyReportDialogComponent;
            instance.assessmentRequest = assessmentRequest;
            instance.assessmentRequestId = assessmentRequest.assessmentRequestId;
            instance.proxyUsers = results;
            instance.clientUsers = users;
          });
      });
  }
}
