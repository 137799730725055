  
  <div class="row">
    <div class="col-4">
      <h1 style="display:inline; position:relative; top:-5px;">Hiring Outcome</h1>
    </div>
    <div class="col-2">
      <i style="font-size: 28px; display:inline; color:#333F48;" class="fa fa-question-circle-o center-block" ngbTooltip="Please keep hiring decision up to date by checking the boxes below. To view older records, click on the Completed Date to change the date range." placement="left" container="body"></i>
      <action-button class="pull-right action-secondary" (actionClick)="exportDataAsCsv()" [iconClass]="'fa fa-save'" >
          Export
        </action-button>
    </div>
    <div class="col-6">
      <div class="pull-right">
        Quick Search:
        <input type="text" [(ngModel)]="inputQuickSearch" id="filter-text-box" placeholder="Type here..." (keyup)="onFilterTextBoxChanged()"/>
        <button (click)="resetFilters()">Reset Filters</button>
      </div>
    </div>
  </div>
  
  <div class="row" style="height:90%">
    <div class="col" style="Width: 100%;Height:100%;" >
      <ag-grid-angular style="Width: 100%;Height:100%;" class="ag-theme-material" [gridOptions]="gridOptions"></ag-grid-angular>
    </div>
  </div>
  <div class="row" style="position: relative; top: -40px;">
    <div class="col-4">
      <button style="margin-left:20px" type="button" class="btn btn-primary" (click)="onDone()" [disabled]="cachedOutcomeUpdates.size == 0"><span class="fa fa-check"></span>Done</button>
      <button type="button" class="btn btn-cancel" (click)="dialogRef.close()"><span class="fa fa-ban"></span>{{cancelButtonText}}</button>
    </div>
  </div>

