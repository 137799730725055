<div class="modal-header">
  <h4 class="modal-title">Missing Resume</h4>
  <button type="button" class="close" aria-label="Close" (click)="onNotNow()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>You did not upload a resume for this candidate. Do you have one available to upload?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="onYes()"><span class="fa fa-cloud-upload"></span> Yes</button>
  <button type="button" class="btn btn-default" (click)="onNo()"><span class="fa fa-ban"></span> No</button>
  <button type="button" class="btn btn-primary" (click)="onNotNow()"><span class="fa fa-envelope"></span> Not Now - I will email resume to testing@leadershipall.com later.</button>
</div>
