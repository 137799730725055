<div class="modal-header">
  <h4 class="modal-title">Delete Assessment Request</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Are you sure you want to delete this assessment request for candidate {{candidateName}}?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="onCancel()"><span class="fa fa-ban"></span> Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onConfirm()"><span class="fa fa-trash"></span> Delete</button>
</div>
