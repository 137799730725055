<div class="modal-header">
  <h4 class="modal-title">Additional Service</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancelClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>What kind of services are you requesting for candidate {{candidateName}}?</p>
</div>
<div class="modal-footer">
  <!-- Disabled rather than fix the AR level issue at this moment. <button type="button" class="btn btn-primary" (click)="onSelectionPromotionClick()">Selection/Promotion</button> -->
  <button type="button" class="btn btn-primary" (click)="onUpgradeClick()">Upgrade Request</button>
  <button type="button" class="btn btn-primary" (click)="onDevelopmentFeedbackClick()">Development Feedback</button>
  <button type="button" class="btn btn-cancel" (click)="onCancelClick()"><span class="fa fa-ban"></span> Cancel</button>
</div>
